import * as Sentry from '@sentry/nextjs';
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';

import { useEventListener } from '@/hooks/useEventListener';
import { SessionKey } from '@/utils/constants/constants';
import { parseJSON } from '@/utils/parseJSON';

declare global {
	interface WindowEventMap {
		'session-storage': CustomEvent;
	}
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

/**
 * Adapted from usehooks-ts useLocalStorage, to instead use Session Storage.
 *
 * See: https://usehooks-ts.com/react-hook/use-local-storage
 */
export function useSessionStorage<T>(
	key: SessionKey,
	initialValue: T
): [T, SetValue<T>] {
	// Get from session storage then
	// parse stored json or return initialValue
	const readValue = useCallback((): T => {
		// Prevent build error "window is undefined" but keep keep working
		if (typeof window === 'undefined') {
			return initialValue;
		}

		try {
			const item = window.sessionStorage.getItem(key);
			return item ? (parseJSON(item) as T) : initialValue;
		} catch (error) {
			return initialValue;
		}
	}, [initialValue, key]);

	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState<T>(readValue);

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to sessionStorage.
	const setValue: SetValue<T> = useCallback(
		(value) => {
			// Prevent build error "window is undefined" but keeps working
			if (typeof window == 'undefined') {
				Sentry.captureMessage(
					`Tried setting sessionStorage key "${key}" even though environment is not a client`
				);
			}

			try {
				// Allow value to be a function so we have the same API as useState
				const newValue = value instanceof Function ? value(storedValue) : value;

				// Save to session storage
				window.sessionStorage.setItem(key, JSON.stringify(newValue));

				// Save state
				setStoredValue(newValue);

				// We dispatch a custom event so every useSessionStorage hook are notified
				window.dispatchEvent(new Event('session-storage'));
			} catch (error) {
				Sentry.captureException(error);
				// eslint-disable-next-line no-console
				console.warn(`Error setting sessionStorage key “${key}”:`, error);
			}
		},
		[key, storedValue]
	);

	useEffect(() => {
		setStoredValue(readValue());
	}, []);

	const handleStorageChange = useCallback(() => {
		setStoredValue(readValue());
	}, [readValue]);

	// this only works for other documents, not the current one
	useEventListener('storage', handleStorageChange);

	// this is a custom event, triggered in writeValueToSessionStorage
	// See: useSessionStorage()
	useEventListener('session-storage', handleStorageChange);

	return [storedValue, setValue];
}
