import { Alert } from '@krakentech/coral';
import clsx from 'clsx';
import { FC } from 'react';

import { PostcodeForm } from '@/components/shared/PostcodeForm';
import { justifyMap } from '@/components/storyblok/utils/constants';
import { CMSFriendsProps } from '@/types/storyblok';
import { GridOperatorName } from '@/utils/constants/industry/gridOperator';

type CMSQuoteButtonProps = {
	blok: {
		affiliate_subdomain?: string;
		background: 'primary' | 'secondary';
		button_text: string;
		label_text: string;
		placeholder_text: string;
		restrict_by_grid_operator?: GridOperatorName;
		text_align: 'left' | 'right' | 'center';
	};
	props?: CMSFriendsProps;
};

export const CMSQuoteButton: FC<CMSQuoteButtonProps> = ({
	blok: {
		affiliate_subdomain,
		label_text,
		background,
		button_text,
		placeholder_text,
		restrict_by_grid_operator,
		text_align,
	},
	props,
}) => {
	return (
		<div
			className={clsx(`w-full py-10 px-4`, {
				'bg-primary': background === 'primary',
				'bg-secondary': background === 'secondary',
			})}
		>
			<div className="max-w-5xl lg:mx-auto">
				{props?.isErrorState ? (
					<Alert severity="error" icon={false}>
						{/*  You are not using a valid referral code. Please check your link again.
								If you continue, you won't get the referral bonus. */}
						有効な紹介コードが使用されていません。リンクを再度ご確認ください。
						このまま進むと特典が適⽤されません。
					</Alert>
				) : null}
			</div>
			<div className={`mx-auto flex max-w-3xl ${justifyMap[text_align]} `}>
				<PostcodeForm
					className={justifyMap[text_align]}
					variant="cms"
					analyticsVariant="blog"
					postcodeButtonText={button_text}
					postcodeInputLabelText={label_text}
					postcodePlaceholderText={placeholder_text}
					affiliateSubdomain={affiliate_subdomain}
					referralCode={props?.referralCode}
					restrictByGridOperator={restrict_by_grid_operator}
				/>
			</div>
		</div>
	);
};
