import { Alert, Button } from '@krakentech/coral';
import { FormikCheckbox } from '@krakentech/coral-formik';
import * as Sentry from '@sentry/nextjs';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';
import * as Yup from 'yup';

import { Container } from '@/components/storyblok/bloks/Container';
import {
	RICH_TEXT_CONSTANTS,
	RICH_TEXT_NODE_RESOLVERS_HEADING,
} from '@/components/storyblok/utils/constants';
import { Spinner } from '@/components/svgs/Spinner';
import { useDemandResponse } from '@/hooks/useDemandResponse';
import graphqlClient from '@/services/graphql-client';
import { CampaignProps } from '@/types/storyblok';

type FormProps = {
	blok: {
		campaign_name: string;
		checkbox_text?: unknown;
	};
	onSubmit: () => Promise<void>;
	props?: CampaignProps;
};

export const CampaignForm: FC<FormProps> = ({ props, blok }) => {
	const [showError, setShowError] = useState(false);
	const router = useRouter();
	const { t } = useTranslation();

	const handleError = (errors: unknown, mutation: string) => {
		Sentry.captureMessage(`Error in ${mutation} mutation.`, {
			extra: { errors },
		});
		setShowError(true);
	};
	const demandResponseQuery = useDemandResponse({
		accountNumber: props?.account?.number ?? '',
	});
	const { isPending, mutate } = useMutation({
		mutationKey: ['updateDemandResponseCampaignParticipantCommsPreferences'],
		mutationFn:
			graphqlClient.updateDemandResponseCampaignParticipantCommsPreferences,
		onSuccess: (data) => {
			const { updateDemandResponseCampaignParticipantCommsPreferences } = data;
			if (
				updateDemandResponseCampaignParticipantCommsPreferences.__typename ===
				'CommonError'
			) {
				handleError(
					updateDemandResponseCampaignParticipantCommsPreferences.message,
					'updateDemandResponseCampaignParticipantCommsPreferences'
				);
			} else if (
				updateDemandResponseCampaignParticipantCommsPreferences.__typename ===
				'DemandResponseCampaignParticipantCommsPreferencesUpdated'
			) {
				router.reload();
			} else {
				handleError(
					'Unexpected mutation response type',
					'updateDemandResponseCampaignParticipantCommsPreferences'
				);
			}
		},
	});
	const handleSubmit = async () => {
		if (
			props?.participationStatus !== 'LOGGED_IN_BUT_NOT_OPTED_IN' ||
			!props?.account?.number
		) {
			/**
			 * @todo (Mary) This will be shown to marketing/design members if they place the CampaignForm outside of a CampaignVisibilityWrapper.
			 * In a future PR when I update how the form behaves when a user fails to sign up (in general backend doesn't send an error response)
			 * I will remove this.
			 */
			window.alert(t('obj:errors.please-login'));
		}
		mutate({
			input: {
				accountNumber: props?.account?.number,
				isOptedIntoDemandResponseEvents: true,
				isOptedIntoEmails: true,
			},
		});
	};
	const isLoading = demandResponseQuery.isLoading || isPending;
	return (
		<Container className="flex w-full items-center justify-center py-4">
			<Formik
				validationSchema={Yup.object().shape({
					campaignTermsAndConditions: Yup.boolean().oneOf(
						[true],
						t('common:errors.agreement-to-terms-and-conditions')
					),
				})}
				validateOnMount={true}
				initialValues={{ campaignTermsAndConditions: false }}
				onSubmit={handleSubmit}
			>
				{() => (
					<Form className="w-full">
						<div className="flex flex-col" id="t&c-section">
							<FormikCheckbox
								id="agree-to-campaign-terms-and-conditions-checkbox"
								aria-label="agree-to-campaign-terms-and-conditions-checkbox"
								name="campaignTermsAndConditions"
								label={
									<span className="mb-4 inline-block self-start text-left text-base md:mb-0">
										{render(blok.checkbox_text, {
											...RICH_TEXT_CONSTANTS(props),
											nodeResolvers: RICH_TEXT_NODE_RESOLVERS_HEADING,
										})}
									</span>
								}
							/>
							<div className="pt-8">
								<Button
									fullWidth
									type="submit"
									disabled={isLoading}
									loading={isLoading}
									loadingLabel={<Spinner width="32" height="32" />}
								>
									<span>{t('obj:storyblok.campaign.register-now')}</span>
								</Button>
							</div>
							{showError && (
								<div className="my-4" data-testid="error-opting-in-alert">
									<Alert severity="error" onClose={() => setShowError(false)}>
										{t('common:errors.something-went-wrong')}
									</Alert>
								</div>
							)}
						</div>
					</Form>
				)}
			</Formik>
		</Container>
	);
};
