import {
	CUSTOMER_NAME_MAX_LENGTH,
	KVA_VALUES,
} from '@/utils/constants/constants';
import { currencyFormatter } from '@/utils/formatters/currencyFormatter';

export const errorMessages = {
	oldPasswordRequired: '現在のパスワードを入力してください', // Enter your current password
	oldPasswordMatches: '現在のパスワードは8文字以上です', // Your current password is more than 8 digits
	passwordRequirements: '半角英数8文字以上のパスワードを入力してください', // Enter more than 8 half width alphanumeric digits
	newPasswordRequired: 'パスワードを入力してください', // Enter your pass word
	newPasswordConfirmedRequired:
		'確認のため新しいパスワードを再度入力してください', // Enter your new password again to confirm
	passwordConfirmedTest: 'パスワードと確認用パスワードが一致していません', // Not matched with you password
	pleaseSelectPayment: 'お支払い方法を選んでください', // Please select a payment method
	required: '必要項目です', // Required
	mustBeXNumbersLong: (length: number): string => `${length}桁が必要です`, // Must have X digits (Note: This translation only works for numbers). Use something else if you're measuring length of string (not number)
	amountMustBeGreaterThanXYen: (number: number): string =>
		`${currencyFormatter.format(number)}円以上の金額を入力する必要があります`, // `Amount must be at least ¥ X jpy`
	amountMustBeLessThanXYen: (number: number): string =>
		`${currencyFormatter.format(number)}円以下の金額を入力する必要があります`, // `Amount must be at most ¥ X jpy`
	textLengthMustBeLessThanXCharacters: (number: number): string =>
		`${number}文字以内で入力してください` /* Please enter equal or less than X characters */,
	invalidPostcode: '半角数字で入力してください (例: 103-0015）', // Enter a valid half width alphanumeric postcode
	invalidEmail:
		'無効なメールアドレスのようです。再度間違いないかご確認下さい。', // This doesn't look like a valid email address, please check the details again.
	invalidCreditCardNumber:
		'有効なクレジットカード番号を半角数字で入力してください' /* "Enter a valid half width alphanumeric credit card number" */,
	invalidCreditCardExpiry:
		'有効期限を月/年の形式で入力してください 例: 12/25' /* "Enter the expiration date in month/year format, e.g. 12/25" */,
	invalidCreditCardCVC:
		'有効なセキュリティーコードを入力してください' /* "Enter a valid security code" */,
	mustBeKatakana:
		'全角カタカナで入力してください' /* "Enter it in full width katakana" */,
	mustBeNumber: '半角数字で入力してください' /* "Enter half width digits" */,
	mustBePhoneNumber:
		'有効な電話番号を半角数字で入力してください' /* "Enter half width digits phone number" */,
	mustBeMobileNumber:
		'有効な携帯番号を半角数字で入力してください' /* "Enter half width digits mobile number" */,
	enterOnlyBasicLatin:
		'半角英数記号で入力してください' /* "Enter only Basic Latin (half width alphanumeric characters or symbols)." */,
	invalidSupplyPointId:
		'22桁の半角数字で入力してください' /* "Enter 22 half width digits" */,
	unsupportedPostcode:
		'現在、あなたの地域にはエネルギーを供給していません。' /* Unsupported area */,
	invalidBankAccountHolderNameLength:
		'30文字以内で入力してください' /* Please enter less than 30 characters */,
	invalidBankCode:
		'半角数字4桁で入力してください' /* Enter 4 half width digits bank code */,
	invalidBankBranch:
		'半角数字3桁で入力してください' /* Enter 3 half width digits bank code */,
	invalidBankAccountNumber:
		'半角数字7桁で入力してください' /* Enter 7 half width digits bank code */,
	invalidKanjiName:
		'常用漢字、アルファベット、もしくはカタカナで入力してください' /* Enter commonly used kanji, alphabet or katakana */,
	invalidBankHolderName:
		'入力に全銀フォーマットに一致しない文字が含まれています。英(大文字)数カナ空白および "ﾞﾟ( )｢ ｣/-.¥ のみご使用いただけます' /* Your input includes invalid characters. Only capitalised letters, number, katakana, spaces, and the following symbols are accepted: "ﾞﾟ( )｢ ｣/-.¥  */,
	invalidDate:
		'西暦8桁を半角数字で入力してください' /* Please enter a valid date. */,
	agreementToTermsAndConditions:
		'規約をお読みいただき、チェックボックスにチェックを入れて下さい' /* Please read the terms and conditions and check the box */,
	generalError:
		'エラーが発生しました。恐れ入りますがもう一度お試しください。' /* Something went wrong. Please try again */,
	passwordTooCommon:
		'個人情報に類似する、もしくは推測されやすいパスワードは設定できません' /* 'This password is too common or too similar your personal info' */,
	passwordTooShort:
		'パスワードが短すぎます。 8桁以上のより強力なパスワードを入力してください' /* Your password in too short */,
	mustBeWithin6Months:
		'おっと！6ヶ月以上先の開始日を指定することはできません。' /* Oops! You cannot specify a start date more than 6 months in advance. */,
	mustBeAfterDate: (date: string): string =>
		`${date}以降の契約変更日を設定してください` /* Please set a contract change date after {date} */,
	mustBeBeforeDate: (date: string): string =>
		`${date}以前の契約変更日を設定してください` /* Please set a contract change date before {date} */,
	mustBeFutureDate:
		'おっと！過去の日付が入力されているようです。未来の日付をご入力ください' /* Oops! It looks like you put a date in the past. We can only work with future dates. */,
	mustNotBeHoliday:
		'年末年始を含む祝祭日はご登録いただけません。' /* Holidays including the year-end and New Year holidays cannot be registered. */,
	undeliverableEmail:
		'メールアドレスが無効なようです' /* The entered email address seems to be undeliverable */,
	privacyPolicyConsentRequired:
		'個人情報の取り扱いに同意してください' /* Please agree to the privacy policy */,
	failedFetchingData: 'データの取得に失敗しました' /* Failed fetching data */,
	invalidkVA: `${Math.min(...KVA_VALUES).toString()}から${Math.max(
		...KVA_VALUES
	).toString()}までの値を入力してください。`,
	invalidNameLength: `${CUSTOMER_NAME_MAX_LENGTH}文字以内で入力してください` /* Please enter less than CUSTOMER_NAME_MAX_LENGTH characters */,
	unableToLogin: 'ログインできませんでした' /* "Could not login" */,
};
