import { useRouter } from 'next/router';
import {
	createContext,
	FC,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react';

import { useAuthContext } from '@/components/contexts/auth';
import graphqlClient from '@/services/graphql-client';
import {
	Account,
	AccountQuery,
	AccountViewerQuery,
} from '@/services/typed-graphql-sdk';
import { compareProperties } from '@/utils/compareProperties';
import { DATE_PLUS_2_MONTHS } from '@/utils/constants/constants';
import { PAGES } from '@/utils/constants/pages';
import { noop } from '@/utils/noop';

export type AccountContextValue = {
	account: Maybe<Account>;
	accountViewer: Maybe<AccountViewerQuery['viewer']>;
	email?: string;
	isError?: boolean;
	isLoading?: boolean;
	setEmail?: (value: string) => void;
};

export const AccountContext = createContext<AccountContextValue>({
	account: null,
	accountViewer: null,
	isError: false,
	isLoading: true,
	email: '',
	setEmail: noop,
});

export const useAccountContext = (): AccountContextValue =>
	useContext(AccountContext);

export type AccountProviderProps = {
	children: ReactNode;
};

export const AccountProvider: FC<AccountProviderProps> = ({ children }) => {
	const router = useRouter();
	const { isCheckingAuthentication, isLoggedIn } = useAuthContext();
	const [account, setAccount] = useState<Account>();
	const [email, setEmail] = useState('');
	const [isAccountError, setIsAccountError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [accountViewer, setAccountViewer] =
		useState<AccountViewerQuery['viewer']>();

	const handleAccountQueryError = () => {
		setIsAccountError(true);
		setIsLoading(false);
	};

	useEffect(() => {
		async function fetchAndSetAccount() {
			const { viewer } = await graphqlClient.accountViewer();
			setAccountViewer(viewer);
			const activeOnDate = DATE_PLUS_2_MONTHS(new Date());

			/**
			 * Account number in the URL is the first source of truth.
			 * If the URL contains an `[accountNumber]` param:
			 *  1. check if the URL account number matches any viewer accounts and return that if it matches
			 *  2. otherwise, push to /account page
			 */
			const accountNumberFromUrl = router?.query?.accountNumber as string;

			const handleAccountQuerySuccess = ({ account }: AccountQuery) =>
				setAccount({
					...account,
					// @ts-expect-error
					properties: account?.properties?.sort(compareProperties),
				});
			setIsLoading(false);

			if (accountNumberFromUrl) {
				const loggedInAccount = viewer?.accounts?.find(
					(account) => account?.number === accountNumberFromUrl
				);
				loggedInAccount?.number
					? graphqlClient
							.account({
								accountNumber: loggedInAccount.number,
								activeOnDate,
							})
							.then(handleAccountQuerySuccess)
							.catch(handleAccountQueryError)
					: router.push(PAGES.accountRoot);
			} else {
				const selectedAccountInLocalStorage =
					window.localStorage.getItem('selectedAccount');
				const selectedAccount = viewer?.accounts?.find(
					(account) => account?.number === selectedAccountInLocalStorage
				);

				const accountNumber = selectedAccount
					? selectedAccountInLocalStorage
					: viewer?.accounts?.[0]?.number;

				if (!accountNumber) {
					throw new Error('Account number is not in local storage or viewer');
				}

				graphqlClient
					.account({
						accountNumber,
						activeOnDate,
					})
					.then(handleAccountQuerySuccess)
					.catch(handleAccountQueryError);
			}
		}

		if (!isCheckingAuthentication && isLoggedIn) {
			setIsLoading(true);
			fetchAndSetAccount();
		}
	}, [
		isCheckingAuthentication,
		router?.query?.accountNumber,
		isLoggedIn,
		router.asPath,
	]);

	return (
		<AccountContext.Provider
			value={{
				account,
				accountViewer,
				isError: isAccountError,
				isLoading,
				email,
				setEmail,
			}}
		>
			{children}
		</AccountContext.Provider>
	);
};
