import { FC, HTMLAttributes, ReactNode } from 'react';

import { CookiesBanner } from '@/components/layouts/landingPage/CookiesBanner';
import { Navbar } from '@/components/layouts/landingPage/Navbar';
import { Footer } from '@/components/layouts/main/Footer';
import { IncidentStatusBanner } from '@/components/shared/IncidentStatusBanners';

interface Props {
	children: ReactNode;
	className?: HTMLAttributes<HTMLDivElement>['className'];
}

const Layout: FC<Props> = ({ children, className = '' }) => (
	<div className={`text-ice ${className}`}>
		<Navbar />
		<IncidentStatusBanner />
		{children}

		<div className="mt-24">
			<Footer linksVariant="list" />
			<CookiesBanner />
		</div>
	</div>
);

export { Layout };
