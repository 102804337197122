import { Button as CoralButton } from '@krakentech/coral';
import { FC } from 'react';

import { Container } from '@/components/storyblok/bloks/Container';
import { ButtonEvent } from '@/utils/constants/analytics';
import { sendStoryblokButtonAnalytics } from '@/utils/googleAnalytics';

export type ButtonProps = {
	blok: {
		background_color?: 'bg-primary' | 'bg-secondary' | '';
		button_align?: 'justify-center' | 'justify-start' | 'justify-end';
		button_variant?: 'contained' | 'outlined' | 'text' | 'ghost';
		ga_event?: ButtonEvent;
		mail_to_body?: string;
		mail_to_subject?: string;
		size?: 'standard' | 'full-width';
		target?: '_self' | '_blank';
		text: string;
		url: string;
		variant?: 'primary' | 'secondary';
	};
};

export const Button: FC<ButtonProps> = ({
	blok: {
		text,
		size = 'standard',
		url,
		variant = 'primary',
		button_variant = 'contained',
		button_align = 'justify-center',
		background_color = 'bg-primary',
		ga_event,
		mail_to_body = '',
		mail_to_subject = '',
		target = '_blank',
	},
}) => {
	const handleOnClick = () => {
		ga_event && sendStoryblokButtonAnalytics(ga_event);
		const emailSubject = mail_to_subject
			? `subject=${encodeURIComponent(mail_to_subject)}`
			: '';
		const emailBody = mail_to_body
			? `body=${encodeURIComponent(mail_to_body)}`
			: '';

		url && url.startsWith('#')
			? document.getElementById(url.split('#')[1])?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				})
			: emailSubject || emailBody
				? window.open(`${url}?${emailSubject}&${emailBody}`, '_blank')
				: window.open(url, target);
	};

	return (
		<div className={`w-full ${background_color}`}>
			<Container
				className={`flex w-full items-center ${button_align} ${size === 'standard' ? 'md:max-w-[300px]' : ''} py-4`}
			>
				<CoralButton
					target={target}
					variant={button_variant}
					color={variant}
					fullWidth
					onClick={handleOnClick}
				>
					{text}
				</CoralButton>
			</Container>
		</div>
	);
};
