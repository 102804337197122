import Link from 'next/link';
import { FC } from 'react';

export type IncidentStatus =
	| 'HEALTHY'
	| 'INVESTIGATION'
	| 'RESTORED'
	| 'OCCTO_OUTAGE'
	| 'MYPAGE_OUTAGE';

export const VALID_INCIDENT_STATUSES: IncidentStatus[] = [
	'HEALTHY',
	'INVESTIGATION',
	'RESTORED',
	'OCCTO_OUTAGE',
	'MYPAGE_OUTAGE',
];

export function validateIncidentStatus(IncidentStatus: IncidentStatus): void {
	if (!VALID_INCIDENT_STATUSES.includes(IncidentStatus)) {
		throw new Error(
			`NEXT_PUBLIC_INCIDENT_STATUS: ${IncidentStatus} is not a valid IncidentStatus.\n Valid IncidentStatuses are ${VALID_INCIDENT_STATUSES.join(
				', '
			)}`
		);
	}
}

const INCIDENT_STATUS = process.env
	.NEXT_PUBLIC_INCIDENT_STATUS as IncidentStatus;
validateIncidentStatus(INCIDENT_STATUS);

const IncidentInvestigationBanner: FC = () => (
	<div
		aria-label="incident investigation banner"
		className="space-y-2 bg-yabaired p-2 text-center"
		role="banner"
	>
		{/**
         * Thank you for your interest in Octopus Energy.
           We are currently experiencing problems with our website and phone lines.
           We are currently working to determine the cause of the problem and to restore the service as soon as possible.
         */}
		<p>
			オクトパスエナジーをご利用いただき、誠にありがとうございます。
			現在、弊社のホームページおよび電話回線に不具合が発生しております。
			現在、原因究明および早期復旧に向けて対応しております。
		</p>
		{/**
		 * If you have any urgent matter, please contact us by e-mail.
		 */}
		<p>
			緊急のご用件等ございましたら、誠に恐れ入りますが、メールにてご連絡をお願い致します。
			メールアドレス：
			<Link
				href="mailto:hello@octopusenergy.co.jp"
				aria-label="節電へのご協力のお願い"
				className="font-bold underline"
			>
				hello@octopusenergy.co.jp
			</Link>
		</p>
		{/**
         * We will update the information on our website again when the situation is restored.
           We sincerely apologize for any inconvenience this may cause.
         */}
		<p>
			復旧致しましたら再度ホームページにて情報を更新致します。
			お客さまには多大なるご迷惑をおかけしますこと、深くお詫び申し上げます。
		</p>
	</div>
);

const IncidentRestoredBanner: FC = () => (
	<div
		aria-label="incident restored banner"
		className="space-y-2 bg-donatellopurple p-2 text-center"
		role="banner"
	>
		{/**
		 * Recovery has been restored
		 * Website and telephone line malfunctions
		 */}
		<p>※復旧致しました※ホームページおよび電話回線の不具合について</p>
		{/**
         * The website and phone lines were experiencing problems today, but have now been restored and are available for use as usual.
           We sincerely apologize for any inconvenience caused.
         */}
		<p>
			本日、ホームページおよび電話回線に不具合が発生しておりましたが、現在は復旧し、通常通りご利用いただける状態となっております。
			ご迷惑をお掛け致しましたことを深くお詫び申し上げます。
		</p>
	</div>
);

const OCCTOOutageBanner: FC = () => (
	<div
		aria-label="incident restored banner"
		className="space-y-2 bg-warning p-2 text-center text-black"
		role="banner"
	>
		<p>
			お知らせ：3月3日午後7時から3月4日午前5時まで、システムメンテナンスのため、
		</p>
		<p>
			一部機能に影響が出る可能性がありますが、電気供給への影響はございませんのでご安心ください。
			<span role="img" aria-label="右を指す絵文字">
				&nbsp;👉
			</span>
			<Link
				href="/system-maintenance"
				aria-label="システムメンテナンスのお知らせ"
				className="font-bold underline"
			>
				詳しくはコチラ
			</Link>
		</p>
	</div>
);

const MyPageAccessOutageBanner: FC = () => (
	<div
		aria-label="incident restored banner"
		className="space-y-2 bg-warning p-2 text-center text-black"
		role="banner"
	>
		<p>
			お知らせ：現在システムの不具合でマイページへのアクセスに不具合が発生しています。
		</p>
		<p>復旧作業中ですので、しばらく経ってからもう一度お試しください。</p>
	</div>
);

export const IncidentStatusBanner: FC = () =>
	INCIDENT_STATUS === 'INVESTIGATION' ? (
		<IncidentInvestigationBanner />
	) : INCIDENT_STATUS === 'RESTORED' ? (
		<IncidentRestoredBanner />
	) : INCIDENT_STATUS === 'OCCTO_OUTAGE' ? (
		<OCCTOOutageBanner />
	) : INCIDENT_STATUS === 'MYPAGE_OUTAGE' ? (
		<MyPageAccessOutageBanner />
	) : null;
