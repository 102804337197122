import { CURRENT_MONTH_NUMBER } from '@/utils/constants/constants';
import { GridOperatorName } from '@/utils/constants/industry/gridOperator';
import {
	ADJUSTED_AVERAGE_USAGE_TIERS_BY_GRID_OPERATOR_NAME,
	DEFAULT_USAGE_TIER,
	UsageTier,
} from '@/utils/constants/industry/industry';

export const getUsageAmount = (
	selectedArea?: GridOperatorName,
	selectedUsageTier?: UsageTier,
	month?: number
): number => {
	const area = selectedArea ? selectedArea : 'TEPCO';
	const usageTier = selectedUsageTier ? selectedUsageTier : DEFAULT_USAGE_TIER;
	const monthIndex = month ? month - 1 : CURRENT_MONTH_NUMBER - 1;

	return ADJUSTED_AVERAGE_USAGE_TIERS_BY_GRID_OPERATOR_NAME[area][usageTier][
		monthIndex
	];
};
