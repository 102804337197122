import { useRouter } from 'next/router';

import { useSessionStorage } from '@/hooks/useSessionStorage';
import {
	AffiliateByJourneyVariant,
	JourneyVariantByPath,
} from '@/utils/constants/marketing';

/**
 * @returns onUpdateJourney function that:
 * - Clears old affiliate-linked journey affiliate data from SS
 * - Sets new affiliate-linked journey affiliate data to SS
 * - Sets the user-journey to SS
 */
export function useUpdateJourney(): (journey: JourneyVariantByPath) => void {
	const router = useRouter();
	const [currentJourney, setUserJourney] = useSessionStorage<
		JourneyVariantByPath | undefined
	>('user-journey', JourneyVariantByPath.default);
	const [, setAffiliate] = useSessionStorage<string | undefined>(
		'affiliateCode',
		''
	);
	const [, setAffiliateSessionId] = useSessionStorage<string | undefined>(
		'affiliateSessionId',
		''
	);
	const [, setAffiliateSalesChannel] = useSessionStorage<string | undefined>(
		'affiliateSalesChannel',
		''
	);
	const updateJourney = (nextJourney: JourneyVariantByPath) => {
		const { pathname, query } = router;
		const currentAffiliateJourney = AffiliateByJourneyVariant.get(
			currentJourney as JourneyVariantByPath
		);
		const nextAffiliateJourney = AffiliateByJourneyVariant.get(nextJourney);
		if (nextAffiliateJourney) {
			router.query.affiliate = nextAffiliateJourney;
			router.replace({ pathname, query }, undefined, { shallow: true });
		} else if (currentAffiliateJourney) {
			delete router.query.affiliate;
			router.replace({ pathname, query }, undefined, { shallow: true });
			setAffiliate('');
			setAffiliateSessionId('');
			setAffiliateSalesChannel('');
		}
		setUserJourney(nextJourney);
	};
	return updateJourney;
}
