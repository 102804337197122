import {
	BankAccountTypeChoices,
	RefundBankAccountTypeChoices,
} from '@/services/typed-graphql-sdk';
import { CardType } from '@/utils/constants/payments';

const VALID_EMAIL_ADDRESS_LOCAL_PART = `[a-zA-Z0-9-][a-zA-Z0-9-!#$%&'*+/=?^_\`{}|~.]*`;

const VALID_EMAIL_ADDRESS_DOMAIN = '[a-zA-Z0-9-]{1,63}(\\.[a-zA-Z0-9-]+)+';

const KANJI = '[㐀-䶵一-鿋豈-頻]';
const KANJI_NAME_SYMBOLS = '[々]';
const KATAKANA_FW = '[゠-ヿ]';
const HIRAGANA = '[ぁ-ゖ]';
const BASIC_LATIN_UC = '[A-Z]';
const BASIC_LATIN_LC = '[a-z]';
const PUNCTUATION = "[-'’]";
const SPACES = '[ 　]';
const PARENTHESES = '[()]';
const BRACKET = '[【】]';

export const validationRegex = {
	password: /^.{8,}$/, // minimum 8 half width digits
	xNumberOfDigits: (length: number): RegExp => {
		const regex = `^\\d{${length}}$`;
		return new RegExp(regex);
	},
	date: /^\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/,
	postcode: /^\d{3}-\d{4}/,
	bankAccountType: new RegExp(
		`^${Object.values(BankAccountTypeChoices).join('|')}$`
	),
	refundBankAccountType: new RegExp(
		`^${Object.values(RefundBankAccountTypeChoices).join('|')}$`
	),
	creditCardNumber: /^(\d{14})|(\d{15})|(\d{16})|(\d{4} ){3}(\d{2,4})$/,
	isAMEXCreditCardNumber: /^3(4|7)/,
	isMastercardNumber:
		/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
	isDinersClubCardNumber: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
	isDiscoverCardNumber:
		/^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
	isJCBCardNumber: /^(?:2131|1800|35\d{3})\d{11}$/,
	isVisaCardNumber: /^4[0-9]{12}(?:[0-9]{3})?$/,
	AMEXCreditCardNumber: /^3(4|7)/,
	creditCardExpiry: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
	creditCardExpiryOnboarding: /^(0[1-9]|1[0-2]) ?\/ ?(20[0-9]{2}|[0-9]{2})$/,
	creditCardCVC: /^\d{3}/,
	AMEXCreditCardCVC: /^\d{4}/,
	isKatakana: /^[\u3000\u30A0-\u30FF\sー]+$/,
	isNumber: /^\d*\.?\d*$/,
	isTokyoGasCustomerNumber: /^1\d{3}(-|)\d{3}(-|)\d{4}$/,
	isGasSPIN: /^0\d{2}(-|)\d{4}(-|)\d{4}(-|)\d{4}(-|)\d{2}$/,
	isJapanMobileNumber: /^0[5789]0-?\d{4}-?\d{4}$/,
	isMobileStartingDigits: /^0[5789]0/,
	isHyphendAlphanumeric: /^[0-9a-zA-Z\\-]+$/,
	isAlphanumeric: /^[a-zA-Z0-9]+$/,
	isValidSPIN:
		/^(0[1-9](-|)[0-1]|10(-|)[0-1])\d{3}(-|)\d{4}(-|)\d{4}(-|)\d{4}(-|)\d{4}$/,
	isValidGPIN:
		/^(0[1-9](-|)[0-1]|10(-|)[0-1])\d{3}(-|)\d{4}(-|)\d{4}(-|)\d{4}(-|)\d{4}$/,
	isValidEmail: new RegExp(
		`^${VALID_EMAIL_ADDRESS_LOCAL_PART}@(${VALID_EMAIL_ADDRESS_DOMAIN})$`
	),
	bankCode: /^\d{4}$/,
	bankBranch: /^\d{3}/,
	bankAccountNumber: /^\d{7}$/,
	isKanjiName: new RegExp(
		`^(${[
			KANJI,
			KANJI_NAME_SYMBOLS,
			'\\d',
			KATAKANA_FW,
			HIRAGANA,
			BASIC_LATIN_UC,
			BASIC_LATIN_LC,
			PUNCTUATION,
			SPACES,
			PARENTHESES,
			BRACKET,
		].join('|')})+$`
	),
	isValidBankAccountHolderName:
		/^[\u30A0-\u30FF\uFF61-\uFF9F\u0041-\u005A\u0020\u3000ﾞﾟ()｢｣/.¥\-゛゜（）「」／ー￥0-9０-９\uFF21-\uFF29\uFF2A-\uFF2F\uFF30-\uFF39\uFF3A]+$/,
	postcodeAndComma: /, \d{3}-\d{4}/,
	/**
	 *  accountRoute: Matches any route containing or ending with 'account'.
	 */
	accountRoute: /\/account[/|\s]/,
	/**
	 *  loginRoute: Matches any route starting with 'login'.
	 */
	loginRoute: /\/login/,
	/**
	 *  requestResetPasswordRoute: Matches any route starting with 'request-reset-password'.
	 */
	requestResetPasswordRoute: /\/request-reset-password/,
	/**
	 *  onboardingRoute: Matches any route starting with 'join' or 'shortcut'.
	 */
	onboardingRoute: /\/join|\/shortcut/,
	isBasicLatin:
		/^[ -~]*$/ /**@see  https://en.wikipedia.org/wiki/List_of_Unicode_characters#Basic_Latin*/,
};

export const cardRegexByType: Record<
	Exclude<CardType, CardType.OTHER>,
	RegExp
> = {
	[CardType.AMEX]: validationRegex.isAMEXCreditCardNumber,
	[CardType.VISA]: validationRegex.isVisaCardNumber,
	[CardType.MASTER]: validationRegex.isMastercardNumber,
	[CardType.DISCOVER]: validationRegex.isDiscoverCardNumber,
	[CardType.DINERS]: validationRegex.isDinersClubCardNumber,
	[CardType.JCB]: validationRegex.isJCBCardNumber,
};
