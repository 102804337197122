import { Button, Dialog } from '@krakentech/coral';
import { FormikCheckbox, FormikTextField } from '@krakentech/coral-formik';
import { Formik, FormikValues } from 'formik';
import { TFunction, Trans, useTranslation } from 'next-i18next';
import { FC, Fragment, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { ConstantineLoader } from '@/components/shared/ConstantineLoader';
import { Link } from '@/components/shared/Link';
import { ConstantineLaptop } from '@/components/svgs/ConstantineLaptop';
import { ColorHex } from '@/utils/constants/colors';

type Props = {
	completedDescriptionText: string;
	completedTitleText: string;
	descriptionText: string;
	journey: OnboardingJourneyVariants;
	onBack: () => void;
	open: boolean;
	postcode: string;
	titleText: string;
};
type EmailFormInput = {
	email: string;
	hasAgreedToPrivacyPolicy: boolean;
};
const initialValue: EmailFormInput = {
	email: '',
	hasAgreedToPrivacyPolicy: true,
};

const IMAGE_WIDTH = 150;

const OutOfServiceAreaDialogWithEmailInput: FC<Props> = ({
	titleText,
	descriptionText,
	completedTitleText,
	completedDescriptionText,
	postcode,
	journey,
	onBack,
	open,
}) => {
	const { t } = useTranslation();
	const [isCompleted, SetIsCompleted] = useState<boolean>();
	const onSubmit = (values: FormikValues) => {
		fetch('/api/onboarding/store-interests', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ email: values.email, postcode, journey }),
		}).then((resp) => {
			if (!resp.ok) {
				throw new Error('SUBMIT FAILED');
			}
			SetIsCompleted(true);
		});
	};

	const getSchema = (t: TFunction) =>
		Yup.object().shape({
			email: Yup.string()
				.email(t('errors.invalid-email'))
				.required(t('errors.required')),
			hasAgreedToPrivacyPolicy: Yup.boolean().oneOf(
				[true],
				t('errors.privacy-policy-consent-required')
			),
		});

	const schema = useMemo(() => getSchema(t), [t]);

	const SubscriptionDialog = (
		<div className="space-y-8">
			<h2 className="text-2xl font-bold" id="email-subscription-dialog-title">
				{titleText}
			</h2>
			<p>{descriptionText}</p>
			<Formik
				initialValues={initialValue}
				onSubmit={onSubmit}
				validationSchema={schema}
			>
				{({ isSubmitting, handleSubmit, setFieldValue, isValid }) => {
					return (
						<form onSubmit={handleSubmit} className="flex flex-col gap-8">
							<FormikTextField
								name="email"
								type="email"
								label={t('inputs.email-address')}
								onChange={(e: { target: HTMLInputElement }) => {
									setFieldValue('email', e.target.value);
								}}
							/>
							<FormikCheckbox
								name="hasAgreedToPrivacyPolicy"
								label={
									<p>
										<Trans
											i18nKey="common:inputs.agree-to-privacy-policy"
											components={{
												theLinkItself: <Link href="/privacy" color="light" />,
												linkSuffix: <Fragment />,
											}}
										/>
									</p>
								}
							/>
							<div className="flex gap-4">
								<Button fullWidth onClick={onBack}>
									{t('try-again')}
								</Button>
								<Button
									disabled={isSubmitting || !isValid}
									loading={isSubmitting ? true : undefined}
									loadingLabel={
										<ConstantineLoader color={ColorHex.purplehaze} />
									}
									fullWidth
									type="submit"
								>
									{t('register')}
								</Button>
							</div>
						</form>
					);
				}}
			</Formik>
		</div>
	);

	const EmailSubscriptionCompletedDialog = (
		<div className="space-y-8">
			<h2 className="text-2xl font-bold" id="email-subscription-dialog-title">
				{completedTitleText}
			</h2>
			<p>{completedDescriptionText}</p>
			<div className="flex items-center justify-center">
				<ConstantineLaptop width={IMAGE_WIDTH} />
			</div>
			<div>
				<Button fullWidth onClick={onBack}>
					{t('close')}
				</Button>
			</div>
		</div>
	);

	return (
		<Dialog
			ariaLabelledBy="email-subscription-dialog-title"
			onClose={onBack}
			open={open}
		>
			{isCompleted ? EmailSubscriptionCompletedDialog : SubscriptionDialog}
		</Dialog>
	);
};

export { OutOfServiceAreaDialogWithEmailInput };
