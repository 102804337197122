import { addMonths, format, subDays, subMonths } from 'date-fns';

import { FeatureName } from '@/features/featureFlags';
import { Amperage } from '@/services/typed-graphql-sdk';
import { range } from '@/utils/range';

/* Times and dates */

export const ONE_MINUTE_IN_MILLISECONDS = 60 * 1000;
export const NUM_SECONDS_IN_ONE_MINUTE = 60;
export const NUM_SECONDS_IN_ONE_DAY = NUM_SECONDS_IN_ONE_MINUTE * 60 * 24;

/**
 * The number of the current month, from 1 to 12.
 */
export const CURRENT_MONTH_NUMBER = Number(format(new Date(), 'M'));

/**
 * The number of the next month, from 1 to 12.
 * If current month is 1, the last month is 12.
 */
export const LAST_MONTH_NUMBER = Number(format(subMonths(new Date(), 1), 'M'));

/**
 * The number of the next month, from 1 to 12.
 * If current month is 1, the last month is 1.
 */
export const MONTH_BEFORE_LAST_MONTH_NUMBER = Number(
	format(subMonths(new Date(), 2), 'M')
);

/**
 * Miliseconds before the state machine transitions out of an 'error' state.
 */
export const ERROR_AUTO_RECOVERY_DELAY_MS = 2500;

/**
 * Seven days from midnight (00:00) of the provided date.
 * If the current date is "2022-02-16T04:21:23.110Z"
 * The returned date is "2022-02-08T15:00:00.000Z"
 */
export const DATE_MINUS_7_DAYS = (date: Date): Date =>
	subDays(date.setHours(0, 0, 0, 0), 7);

/**
 * Two months from the provided date.
 * If the current date is "2022-02-16T04:21:23.110Z"
 * The returned date is "2022-04-16"
 */
export const DATE_PLUS_2_MONTHS = (date: Date): string =>
	format(addMonths(date, 2), 'yyyy-MM-dd');

/* Inputs */

export const MOBILE_NUMBER_LENGTH = '070-5356-7812'.length;

export const DATE_INPUT_LENGTH = 'YYYY/MM/DD'.length;

export const CREDIT_CARD_NUMBER_DIGITS_LENGTH = '1111222233334444'.length;

export const AMEX_CREDIT_CARD_NUMBER_DIGITS_LENGTH = '111122222233333'.length;

export const CREDIT_CARD_EXPIRY_CHARACTER_LENGTH = 'XX/XX'.length;

export const CREDIT_CARD_CVC_DIGITS_LENGTH = '123'.length;

export const AMEX_CREDIT_CARD_CVC_DIGITS_LENGTH = '1234'.length;

export const JAPANESE_POSTCODE_CHARACTER_LENGTH = '153-0053'.length;

export const JPN_BANK_ACCOUNT_HOLDER_NAME_MAX_LENGTH = 30;

export const JPN_BANK_ACCOUNT_NUMBER_LENGTH = '1234567'.length;

export const JPN_POST_BANK_ACCOUNT_NUMBER_LENGTH = '12345678'.length;

export const JPN_BANK_BRANCH_CODE_LENGTH = '001'.length;

export const JPN_POST_BANK_BRANCH_CODE_LENGTH = '12345'.length;

export const BANK_CODE_JAPAN_POST = { code: '9900', name: 'ゆうちょ銀行' };

export const BANK_CODE_MIZUHO = { code: '0001', name: 'みずほ銀行' };

export const SPIN_CHARACTER_LENGTH = '03-0XXX-XXXX-XXXX-XXXX-XXXX'.length;

export const GPIN_CHARACTER_LENGTH = '03-0XXX-XXXX-XXXX-XXXX-XXXX'.length;

export const KVA_VALUES = range(6, 50);

export const monthDropdownOptions = [
	{ value: CURRENT_MONTH_NUMBER },
	{ value: LAST_MONTH_NUMBER },
	{
		value: MONTH_BEFORE_LAST_MONTH_NUMBER,
	},
];

export const OCCTO_ADDRESS_LINE_1_MAX_LENGTH = 60;
export const OCCTO_ADDRESS_LINE_2_MAX_LENGTH = 36;
export const CUSTOMER_NAME_MAX_LENGTH = 254;

/* Misc */

export const XStateDevToolsMode =
	process.env.NEXT_PUBLIC_XSTATE_DEV_TOOLS_MODE === 'true';

/*
TailwindCSS default breakpoints
https://tailwindcss.com/docs/breakpoints
*/
export const TAILWIND_DEFAULT_SM_BREAKPOINT_PX = 640;
export const TAILWIND_DEFAULT_MD_BREAKPOINT_PX = 768;
export const TAILWIND_DEFAULT_LG_BREAKPOINT_PX = 1024;
export const TAILWIND_DEFAULT_XL_BREAKPOINT_PX = 1280;

export const THIS_YEAR = format(new Date(), 'Y');

/* Domain */
export const OCTOPUS_JAPAN_CONTACT_US_EMAIL_ADDRESS =
	'hello@octopusenergy.co.jp';
export const SUPPLIER_CONTRACT_NUMBER_MAX_LENGTH = 30;
export const MIN_STRIPE_PAYMENT_AMOUNT_YEN = 50;
export const MIN_STRIPE_KONBINI_PAYMENT_AMOUNT_YEN = 120;
export const MAX_STRIPE_KONBINI_PAYMENT_AMOUNT_YEN = 300000;
export const MAX_STRIPE_DESCRIPTION_TEXT_LENGTH = 350;

/** Loose assumption of max electricity usage in kWh. */
export const MAX_THEORETICAL_KWH_USAGE_AMOUNT = 99999;
export const MAX_THEORETICAL_KWH_USAGE_AMOUNT_LENGTH =
	MAX_THEORETICAL_KWH_USAGE_AMOUNT.toString().length;

export const DEFAULT_AMPERAGE = Amperage[30];

export const ALLOWED_ORIGINS = [
	/http:\/\/localhost:3000/,
	/https:\/\/tg-oe-consumer-site-[a-z0-9-]*-kraken-tech\.vercel\.app/,
	/https:\/\/oejp-kraken\.systems/,
	/https:\/\/octopusenergy\.co\.jp/,
	/https:\/\/www\.octopusenergy\.co\.jp/,
	/https:\/\/tg-oe-consumer-site\.vercel\.app/,
];

// List of available session keys
export type SessionKey =
	| 'affiliateCode'
	| 'affiliateOrganisationName'
	| 'affiliateSalesChannel'
	| 'affiliateSessionId'
	| 'audioRecordingId'
	| 'emailModalIsDismissed'
	| `isDismissedFeatureByFlag-${FeatureName}`
	| 'join-octopus-form-values'
	| 'move-octopus-form-values'
	| 'moveOutDate'
	| 'movingState'
	| 'referralCode'
	| 'referralSchemeCode'
	| 'shortcut-octopus-form-values'
	| 'dual-fuel-postcode-status'
	| 'user-journey'
	| 'verified-supply-points';
