import Script from 'next/script';
import { FC, useEffect, useState } from 'react';

import { useAnalyticsContext } from '@/components/contexts/analytics';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { sanitizeUrl } from '@/utils/urlHelpers';

export const AnalyticsScripts: FC = () => {
	const { setIsAnalyticsReady } = useAnalyticsContext();
	const [cleanUrl, setCleanUrl] = useState('');
	const [analyticsDisabled] = useLocalStorage(
		'disable-all-analytics',
		// Default value of false so normal users are not impacted.
		false
	);

	useEffect(() => {
		const getCleanUrl = () => {
			const PERSONAL_INFORMATION_ARR = ['username', 'password', 'email'];
			const pageUrl = new URL(sanitizeUrl(window.location.href));

			if (pageUrl.searchParams) {
				PERSONAL_INFORMATION_ARR.map((pii_term) => {
					pageUrl.searchParams.has(pii_term)
						? pageUrl.searchParams.delete(pii_term)
						: null;
				});
			}

			return pageUrl.toString();
		};

		if (!cleanUrl) {
			setCleanUrl(getCleanUrl());
		}
	}, [cleanUrl]);

	return !analyticsDisabled && cleanUrl ? (
		<>
			{/* Google Analytics */}
			<Script
				id="gtag"
				dangerouslySetInnerHTML={{
					__html: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID}', {page_path: window.location.pathname, page_location: '${cleanUrl}' });`,
				}}
				onReady={() => setIsAnalyticsReady(true)}
			/>
			{/* Google Tag Manager */}
			<Script
				id="gtm-script"
				dangerouslySetInnerHTML={{
					__html: `window.dataLayer = window.dataLayer || [];(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`,
				}}
			/>
			{/* Yahoo General Site Tag */}
			<Script
				id="yahoo-script"
				dangerouslySetInnerHTML={{
					__html: `(function () {var tagjs = document.createElement("script");var s = document.getElementsByTagName("script")[0];tagjs.async = true;tagjs.src = "//s.yjtag.jp/tag.js#site=${process.env.NEXT_PUBLIC_YAHOO_SITE_TAG_ID}";s.parentNode.insertBefore(tagjs, s);}());`,
				}}
			/>
			{/* Yahoo Ads Site Retargeting script */}
			<Script
				src="https://s.yimg.jp/images/listing/tool/cv/ytag.js"
				strategy="afterInteractive"
			/>
			<Script
				id="yahoo-script-retargeting"
				dangerouslySetInnerHTML={{
					__html: `window.yjDataLayer = window.yjDataLayer || []; function ytag() { yjDataLayer.push(arguments); } ytag({ "type":"yjad_retargeting", "config":{ "yahoo_retargeting_id": "${process.env.NEXT_PUBLIC_YAHOO_RETARGETING_ID}", "yahoo_retargeting_label": "", "yahoo_retargeting_page_type": "", "yahoo_retargeting_items":[ {item_id: '', category_id: '', price: '', quantity: ''} ] } });`,
				}}
			/>
			{/* Google Optimize */}
			<Script
				id="optimize"
				src={`https://www.googleoptimize.com/optimize.js?id=${process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_ID}`}
			/>
			{/* Hotjar */}
			<Script
				id="hotjar"
				strategy="lazyOnload"
				dangerouslySetInnerHTML={{
					__html: `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:3184002,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
				}}
			/>
		</>
	) : null;
};
