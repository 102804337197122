import { GetTransactionsQuery } from '@/services/typed-graphql-sdk';

// SDK generated TransactionType does not include __typename.
// So we extract a non-nullable TransactionNodeType from the GetTransactionsQuery that includes __typename.
export type TransactionNodeType = NonNullable<
	NonNullable<
		NonNullable<
			NonNullable<GetTransactionsQuery['account']>['transactions']
		>['edges'][0]
	>['node']
> & { previousBalance: number };

export const isChargedTransaction = (
	transaction: TransactionNodeType
): boolean => {
	return (
		transaction.__typename === 'Charge' && transaction.title !== 'Generation'
	);
};

export const isGenerationTransaction = (
	transaction: TransactionNodeType
): boolean => {
	return transaction.title === 'Generation';
};

export const isRefundTransaction = (
	transaction: TransactionNodeType
): boolean => {
	return transaction.title === 'Refund';
};

export const isCreditedTransaction = (
	transaction: TransactionNodeType
): boolean => {
	return (
		transaction.__typename === 'Credit' || transaction.title === 'Generation'
	);
};

export const isPaymentTransaction = (
	transaction: TransactionNodeType
): boolean => {
	return transaction.__typename === 'Payment';
};

export const JapaneseRegex = /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[々〆〤ヶ]+/u;

export const TransactionDescriptionMap = new Map<string, string>([
	['Generation', '入金 (電力買取分)'], // regular billing credit for FIT solar generation
	['Electricity', 'ご請求（電気利用料金）'], // regular billing charge for electricity usage
	['Credit card collection', 'お支払い（クレジットカード）'], // regular payment with credit card
	['Direct debit first collection', 'お支払い（口座振替）'], // regular payment with direct debit
	['Direct debit regular collection', 'お支払い（口座振替）'], // first time regular payment with direct debit
	['Direct debit re-presentation', 'お支払い(口座振替/再試行)'], // same as Direct debit collection
	['Direct debit collection', 'お支払い（口座振替）'], // re-try of direct debi collection
	['Konbini pay', 'お支払い（コンビニ）'], // regular payment with Konbini
	['One time payment', 'お支払い（一時払い）'], // one time payment (regarless of payment method)
	['Referral reward', '紹介特典'], // credit granting of referral bonus
	['Refund', '返金処理'], // credit withdrawal from wallet and transfer to customer's creadit card or bank account
	['Demand Response Reward', '節電チャレンジ特典'], // credit for granting of demand response reward
	['Signup reward', '新規入会ボーナス'], // credit for granting signup bonus
	['METI Relief credit', '電撃値引き(政府の激変緩和措置）'], // credit applied per kWh balance transfer under the METI relief scheme
	['Bank Transfer', 'お支払い（銀行振込）'], // regular payment via bank transfer
]);

export const NewTransactionDescriptionCopyMap = new Map<string, string>([
	['Credit card collection', 'クレジットカード'], // regular payment with credit card
	['Direct debit first collection', '口座振替'], // regular payment with direct debit
	['Direct debit regular collection', '口座振替'], // first time regular payment with direct debit
	['Direct debit re-presentation', '口座振替/再試行'], // same as Direct debit collection
	['Direct debit collection', '口座振替'], // re-try of direct debi collection
	['Konbini payment', 'コンビニ'], // regular payment with Konbini
	['One time payment', '一時払い'], // one time payment (regarless of payment method)
	['Refund', '返金処理'], // credit withdrawal from wallet and transfer to customer's creadit card or bank account
	['Bank Transfer', '銀行振込'], // regular payment via bank transfer
]);

export const NewTransactionTitleCopyMap = new Map<string, string>([
	['Generation', '電力買取分'], // regular billing credit for FIT solar generation
	['Electricity', '電気使用量'], // regular billing charge for electricity usage
	['Credit card collection', 'お支払い'], // regular payment with credit card
	['Direct debit first collection', 'お支払い'], // regular payment with direct debit
	['Direct debit regular collection', 'お支払い'], // first time regular payment with direct debit
	['Direct debit re-presentation', 'お支払い'], // same as Direct debit collection
	['Direct debit collection', 'お支払い'], // re-try of direct debi collection
	['Konbini payment', 'お支払い'], // regular payment with Konbini
	['One time payment', 'お支払い'], // one time payment (regarless of payment method)
	['Referral reward', 'お友達紹介ボーナス'], // credit granting of referral bonus
	['Refund', '返金処理'], // credit withdrawal from wallet and transfer to customer's creadit card or bank account
	['Demand Response Reward', '節電チャレンジ特典'], // credit for granting of demand response reward
	['Signup reward', '新規入会ボーナス、\n地域割、サイト割など'], // credit for granting signup bonus
	['METI Relief credit', 'METI 電撃値引き\n政府の激変緩和措置'], // credit applied per kWh balance transfer under the METI relief scheme
	['Bank Transfer', 'お支払い'], // regular payment via bank transfer
]);

export const REFERRAL_REWARD = 'Referral reward';
export const SIGNUP_REWARD = 'Signup reward';

const TransactionGenericDescriptionMap = {
	Charge: '請求・出金',
	Credit: '入金（特典付与）',
	Payment: 'お支払い',
	Refund: '返金処理',
} as const;

export const getTransactionDescription = (
	transactionType: TransactionNodeType['__typename'],
	title?: string | null | undefined,
	note?: string
): string => {
	const isAlreadyJapanese = title && title.match(JapaneseRegex);

	if (isAlreadyJapanese) {
		return title;
	}

	/**
	 * There is a special case where some sign up bonuses are labelled as 'Referral reward' in Kraken,
	 * but they should be displayed as a 'Signup reward' to the customer.
	 * The note field contains the string that confirms if the bonus should be labelled as a referral or signup bonus.
	 */
	const shouldDisplayNoteAsTitle =
		note && title === REFERRAL_REWARD && note === SIGNUP_REWARD;

	const knownTranslation = shouldDisplayNoteAsTitle
		? TransactionDescriptionMap.get(note)
		: title && TransactionDescriptionMap.get(title);

	if (knownTranslation) {
		return knownTranslation;
	} else {
		return TransactionGenericDescriptionMap[transactionType];
	}
};

export const getTransactionDescriptionCopy = (
	description?: string | null | undefined
): string => {
	if (!description) {
		return '';
	}

	// Already translated to Japanese
	if (description.match(JapaneseRegex)) {
		return description;
	}

	return NewTransactionDescriptionCopyMap.get(description) || '';
};

export const getTransactionTitleCopy = (
	title?: string | null | undefined,
	note?: string | null | undefined
): string => {
	if (!title) {
		return '';
	}

	// Already translated to Japanese
	if (title.match(JapaneseRegex)) {
		return title;
	}

	if (title === 'Referral reward' && note === 'Signup reward') {
		return NewTransactionTitleCopyMap.get(note) || '';
	}
	return NewTransactionTitleCopyMap.get(title) || '';
};
