// @ts-nocheck
// No need to type check this file
import { inspect } from '@xstate/inspect';
import { useMachine as useM, useActor as useA } from '@xstate/react';
import {
	EventObject,
	Interpreter,
	State,
	StateMachine,
	MachineOptions,
	InterpreterOptions,
	SpawnedActorRef,
} from 'xstate';

import { isBrowser } from '@/utils/browser/isBrowser';
import { XStateDevToolsMode } from '@/utils/constants/constants';

/**
 * Runs the XState inspector when NEXT_PUBLIC_XSTATE_DEV_TOOLS_MODE is enabled.
 *
 * When rendering the machine, an inspector window will appear.
 * You might have to disable pop-up blocking in your browser.
 */
export const XStateDevTools = (): void => {
	if (isBrowser() && XStateDevToolsMode) {
		inspect({
			iframe: false, // open in new window
		});
	}
};

/*
 * String literal union type of all possible states including deep nested states
 */
type StatesMatches<T, J = ''> =
	| `${J}${keyof T}`
    | { [K in keyof T]: StatesMatches<T[K]['states'], `${J}${K}.`> }[keyof T]; // eslint-disable-line

/*
 * Typed useActor that provides type safety on:
 * - context
 * - state.matches('deep.nested.state')
 * - events that machine accepts
 *
 */
export const useActor = <TContext, TStateSchema, TEvent>(
	service: SpawnedActorRef<
		TEvent,
		State<TContext, TEvent, TStateSchema, { context: TContext; value: unknown }>
	>
): [
	Omit<State<TContext, TEvent>, 'matches'> & {
		matches: (match: StatesMatches<TStateSchema['states']>) => boolean;
	},
	Sender<TEvent>,
] => useA(service);

/*
 * Typed useMachine that provides type safety on:
 * - context
 * - state.matches('deep.nested.state')
 * - events that machine accepts
 *
 * To get type safety on events with payloads, send an event object with a type
 * property i.e
 *
 * send({ type: "SEND", id: 15 })
 *
 */
export const useMachine = <TContext, TStateSchema, TEvent extends EventObject>(
	machine: StateMachine<TContext, TStateSchema, TEvent>,
	options?: Partial<InterpreterOptions> &
		Partial<MachineOptions<TContext, TEvent>>
): [
	Omit<State<TContext, TEvent>, 'matches'> & {
		matches: (match: StatesMatches<TStateSchema['states']>) => boolean;
	},
	Interpreter<TContext, TStateSchema, TEvent>['send'],
	Interpreter<TContext, TStateSchema, TEvent>,
] => useM(machine, { ...options, devTools: XStateDevToolsMode });
