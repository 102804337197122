import { Property } from '@/services/typed-graphql-sdk';
import { activeStatuses } from '@/utils/constants/industry/industry';

/**
 * Array.Sort compare function for properties ascending by whether they have:
 * - An electricitySupplyPoint
 * - An electricitySupplyPoint with a status included in the activeStatuses
 */
export const compareProperties = (
	a: Maybe<Property>,
	b: Maybe<Property>
): number => {
	const aHasSupplyPoint = Boolean(a?.electricitySupplyPoints);
	const bHasSupplyPoint = Boolean(b?.electricitySupplyPoints);
	if (aHasSupplyPoint && !bHasSupplyPoint) {
		return -1;
	}
	if (!aHasSupplyPoint && bHasSupplyPoint) {
		return 1;
	}
	if (!aHasSupplyPoint && !bHasSupplyPoint) {
		return 0;
	}
	const aHasActiveSupplyPoint = a?.electricitySupplyPoints?.some(
		(spin) => spin?.status && activeStatuses.includes(spin.status)
	);
	const bHasActiveSupplyPoint = b?.electricitySupplyPoints?.some(
		(spin) => spin?.status && activeStatuses.includes(spin.status)
	);
	if (aHasActiveSupplyPoint && !bHasActiveSupplyPoint) {
		return -1;
	}
	if (!aHasActiveSupplyPoint && bHasActiveSupplyPoint) {
		return -0;
	}
	return 0;
};
