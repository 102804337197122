import { Logo } from '@krakentech/coral';
import { Header as OctoHeader } from '@krakentech/coral-organisms';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import { useAccountContext } from '@/components/contexts/account';
import { useAuthContext } from '@/components/contexts/auth';
import { validationRegex } from '@/components/helpers/validationRegex';
import { useCheckHeadless } from '@/hooks/useCheckHeadless';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { isBrowser } from '@/utils/browser/isBrowser';

/**
 * @todo: integrate with i18n once app-router used for all pages
 */
const TempNavMenuCopyJA: Record<string, string> = {
	'nav-menu.menu': 'メニュー',
	'nav-menu.home': 'ホーム',
	'nav-menu.blog': 'ブログ',
	'nav-menu.tariffs': '料金プラン一覧',
	'nav-menu.solar': 'ソーラー＆蓄電池設置',
	'nav-menu.ev': 'EV オクトパス',
	'nav-menu.terms': 'お知らせ•約款類',
	'nav-menu.faqs': 'よくある質問',
	'nav-menu.about-us': '企業概要',
	'nav-menu.career': '採用情報',
	'nav-menu.contact-us': 'お問い合わせ',
	'nav-menu.join': '見積もり＆申し込み',
	'nav-menu.my-page': 'マイページ',
	'nav-menu.usage': '電気使用量',
	'nav-menu.wallet': '請求履歴',
	'nav-menu.email-history': 'メッセージ一覧',
	'nav-menu.logout': 'ログアウト',
	close: '戻る',
};

/**
 * @todo: request and apply english translations
 */
const TempNavMenuCopyEN: Record<string, string> = {
	'nav-menu.menu': 'メニュー',
	'nav-menu.home': 'ホーム',
	'nav-menu.blog': 'ブログ',
	'nav-menu.tariffs': '料金プラン一覧',
	'nav-menu.solar': 'ソーラー＆蓄電池設置',
	'nav-menu.ev': 'EV オクトパス',
	'nav-menu.terms': 'お知らせ•約款類',
	'nav-menu.faqs': 'よくある質問',
	'nav-menu.about-us': '企業概要',
	'nav-menu.career': '採用情報',
	'nav-menu.contact-us': 'お問い合わせ',
	'nav-menu.join': '見積もり＆申し込み',
	'nav-menu.my-page': 'マイページ',
	'nav-menu.usage': '電気使用量',
	'nav-menu.wallet': '請求履歴',
	'nav-menu.email-history': 'メッセージ一覧',
	'nav-menu.logout': 'ログアウト',
	close: '戻る',
};

const Navbar: React.FC = () => {
	const { handleSignOut, isLoggedIn } = useAuthContext();
	const { account } = useAccountContext();
	const router = useRouter();
	const path = isBrowser() && router ? router.pathname : '';
	const { i18n } = useTranslation();
	const [locale, setLocale] = useLocalStorage('i18nextLng', i18n.language);
	const [shouldShowLanguageSelect, setShouldShowLanguageSelect] =
		useState(false);
	const { isBrowserHeadless } = useCheckHeadless();

	const t = useMemo(
		() => (key: string) =>
			locale === 'en' ? TempNavMenuCopyEN[key] : TempNavMenuCopyJA[key],
		[locale]
	);

	const accountNumber = account?.number;
	const headerContentLoggedIn = {
		mainMenu: [
			{ text: t('nav-menu.my-page'), href: '/account' },
			{ text: t('nav-menu.usage'), href: `/account/${accountNumber}/usage` },
			{
				text: t('nav-menu.wallet'),
				href: `/account/${accountNumber}/transactions`,
			},
			{
				text: t('nav-menu.email-history'),
				href: `/account/${accountNumber}/email-history`,
			},
		],
		subMenu: [
			{ text: t('nav-menu.home'), href: '/' },
			{ text: t('nav-menu.blog'), href: '/blogs' },
			{ text: t('nav-menu.tariffs'), href: '/tariffs' },
			{ text: t('nav-menu.solar'), href: '/solar-battery' },
			{ text: t('nav-menu.ev'), href: '/ev' },
			{ text: t('nav-menu.terms'), href: '/terms' },
			{ text: t('nav-menu.faqs'), href: '/faqs' },
			{ text: t('nav-menu.about-us'), href: '/about-us' },
			{ text: t('nav-menu.career'), href: '/career' },
			{ text: t('nav-menu.contact-us'), href: '/contact-us' },
		],
	};
	const headerContentLoggedOut = {
		mainMenu: [
			{ text: t('nav-menu.home'), href: '/' },
			{ text: t('nav-menu.about-us'), href: '/about-us' },
			{ text: t('nav-menu.join'), href: '/join' },
			{ text: t('nav-menu.faqs'), href: '/faqs' },
		],
		subMenu: [
			{ text: t('nav-menu.blog'), href: '/blogs' },
			{ text: t('nav-menu.tariffs'), href: '/tariffs' },
			{ text: t('nav-menu.solar'), href: '/solar-battery' },
			{ text: t('nav-menu.ev'), href: '/ev' },
			{ text: t('nav-menu.terms'), href: '/terms' },
			{ text: t('nav-menu.career'), href: '/career' },
			{ text: t('nav-menu.contact-us'), href: '/contact-us' },
		],
	};
	const headerContent =
		isLoggedIn && accountNumber
			? headerContentLoggedIn
			: headerContentLoggedOut;

	/**
	 * Sync i18n language with locale
	 * @fixme flicker of language display name in the OctoHeader
	 */
	useEffect(() => {
		if (i18n?.language && i18n.language !== locale) {
			i18n?.changeLanguage(locale);
		}
	}, [locale, i18n?.language]);

	useEffect(() => {
		setShouldShowLanguageSelect(
			validationRegex.onboardingRoute.test(path) ||
				validationRegex.accountRoute.test(path) ||
				validationRegex.loginRoute.test(path) ||
				validationRegex.requestResetPasswordRoute.test(path)
		);
	}, [path]);

	return isBrowserHeadless === 'loading' ? (
		<div className="h-[60px] w-full bg-secondary" />
	) : isBrowserHeadless === 'headless' ? null : (
		<OctoHeader
			locales={{ ja: '日本語', en: 'English (Beta)' }}
			currentLocale={i18n.language ?? 'ja'}
		>
			<div className="flex shrink-0" data-testid="nav-menu-button">
				<OctoHeader.MenuToggleButton
					closedText={t('nav-menu.menu')}
					openText={t('close')}
				/>
			</div>

			<div data-testid="navbar-links">
				<OctoHeader.NavMenu>
					<OctoHeader.NavMenuList>
						{headerContent.mainMenu.map((item) => (
							<OctoHeader.NavMenuItem key={item.text}>
								<Link href={item.href} legacyBehavior>
									<a className="relative flex px-4 py-2 text-2xl font-bold text-ice hover:text-voltage sm:text-3xl">
										{item.text}
									</a>
								</Link>
							</OctoHeader.NavMenuItem>
						))}
					</OctoHeader.NavMenuList>

					<OctoHeader.NavMenuList>
						{headerContent.subMenu.map((item) => (
							<OctoHeader.NavMenuItem key={item.text}>
								<Link href={item.href} legacyBehavior>
									<a className="relative flex px-4 py-2 font-bold text-ice hover:text-voltage">
										{item.text}
									</a>
								</Link>
							</OctoHeader.NavMenuItem>
						))}
					</OctoHeader.NavMenuList>
				</OctoHeader.NavMenu>
			</div>

			<OctoHeader.Logo>
				<Logo link="/" withConstantine={true} />
			</OctoHeader.Logo>

			<OctoHeader.ButtonGroup>
				{path.match(validationRegex.accountRoute) ? (
					<OctoHeader.LogOutButton
						onClick={handleSignOut}
						label={t('nav-menu.logout')}
					/>
				) : (
					<OctoHeader.LogInButton
						href={isLoggedIn ? '/account' : '/login'}
						label={t('nav-menu.my-page')}
					/>
				)}
				<div
					className={clsx('flex font-bold', {
						hidden: !shouldShowLanguageSelect,
					})}
				>
					<OctoHeader.LanguageSwitcher
						onLanguageSelect={({ selectedLanguage }) => {
							setLocale(selectedLanguage);
						}}
					/>
				</div>
			</OctoHeader.ButtonGroup>
		</OctoHeader>
	);
};

export { Navbar };
