import {
	CoralConfigOptions,
	CoralThemeOverrides,
	CoralThemeProvider,
	octopusTheme,
} from '@krakentech/coral';
import { CoralStoryblokProvider } from '@krakentech/coral-storyblok';
import { StoryblokComponent } from '@storyblok/react';
import { FC, ReactNode } from 'react';

import { ColorHex } from '@/utils/constants/colors';

type CoralProviderProps = {
	children: ReactNode;
};

const overrides: CoralThemeOverrides = {
	color: octopusTheme.color,
	optionalIndicator: '任意',
	typography: {
		fontFamily: `'Hiragino Sans', 'Hiragino Kaku Gothic Pro', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', 'ＭＳ ゴシック', 'MS Gothic', 'Noto Sans CJK JP', 'TakaoPGothic', sans-serif`,
	},
	components: {
		typography: {
			link: {
				':hover': {
					color: ColorHex.aquamarine,
					textDecoration: 'none',
				},
				':active': {
					borderRadius: '4px',
					outlineColor: ColorHex.soholights,
					outlineOffset: '4px',
					outlineStyle: 'solid',
				},
				':focus-visible': {
					borderRadius: '4px',
					outlineColor: ColorHex.soholights,
					outlineOffset: '4px',
					outlineStyle: 'solid',
				},
			},
		},
	},
};

const coralConfig: CoralConfigOptions = {
	overrides,
	remSize: 0.6,
};

export const CoralProvider: FC<CoralProviderProps> = ({ children }) => {
	return (
		<CoralThemeProvider config={coralConfig}>
			<CoralStoryblokProvider StoryblokComponent={StoryblokComponent}>
				{children}
			</CoralStoryblokProvider>
		</CoralThemeProvider>
	);
};
