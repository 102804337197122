import {
	Alert,
	Button,
	Card,
	Select,
	SelectOption,
	Stack,
} from '@krakentech/coral';
import * as Sentry from '@sentry/nextjs';
import { FC, FormEvent, useState } from 'react';

import { calculateMonthlyRate } from '@/domain/product/simple-octopus';
import {
	GridOperatorName,
	SUPPORTED_SIMPLE_GRID_OPERATOR_NAMES,
} from '@/utils/constants/industry/gridOperator';
import { UsageTier } from '@/utils/constants/industry/industry';
import { currencyFormatter } from '@/utils/formatters/currencyFormatter';
import { sendSimpleOctopusCalculatorAnalytics } from '@/utils/googleAnalytics';
import { toUsageTier } from '@/utils/toUsageTier';

interface RateCalculatorProps {
	blok: {
		area_select_label?: string;
		chubu_unit_fee_yen_kwh?: number;
		chugoku_unit_fee_yen_kwh?: number;
		heading?: string;
		hepco_unit_fee_yen_kwh?: number;
		hokuden_unit_fee_yen_kwh?: number;
		kepco_unit_fee_yen_kwh?: number;
		kyuden_unit_fee_yen_kwh?: number;
		renewable_energy_charge?: number;
		reset_button_text?: string;
		submit_button_text?: string;
		tepco_unit_fee_yen_kwh?: number;
		tohoku_unit_fee_yen_kwh?: number;
		usage_select_label?: string;
		yonden_unit_fee_yen_kwh?: number;
	};
}

interface FormState {
	gridOperator?: GridOperatorName;
	usageTier?: UsageTier;
}

type FormStatus = 'idle' | 'complete' | 'error';

const gridAreaLabelByGridOperatorName: Record<string, string> = {
	HEPCO: '北海道',
	TOHOKU: '東北',
	TEPCO: '関東',
	CHUBU: '中部',
	HOKUDEN: '北陸',
	KEPCO: '関西',
	CHUGOKU: '中国',
	YONDEN: '四国',
	KYUDEN: '九州',
};

const ResetButton: FC<{ label: string; onClick: () => void }> = ({
	label,
	onClick,
}) => {
	return (
		<Button
			type="reset"
			color="secondary"
			variant="outlined"
			onClick={onClick}
			fullWidth
		>
			{label}
		</Button>
	);
};

export const RateCalculator: FC<RateCalculatorProps> = ({
	blok: {
		heading,
		area_select_label = 'エリアを選んでください',
		usage_select_label = '何人でお住まいですか？',
		renewable_energy_charge,
		reset_button_text = 'リセット',
		submit_button_text = '送信',
		hepco_unit_fee_yen_kwh = 34.5,
		tohoku_unit_fee_yen_kwh = 31.5,
		tepco_unit_fee_yen_kwh = 30.5,
		chubu_unit_fee_yen_kwh = 30.7,
		hokuden_unit_fee_yen_kwh = 0,
		kepco_unit_fee_yen_kwh = 0,
		chugoku_unit_fee_yen_kwh = 0,
		yonden_unit_fee_yen_kwh = 0,
		kyuden_unit_fee_yen_kwh = 0,
	},
}) => {
	const gridOperatorUnitFees: Record<string, number> = {
		HEPCO: hepco_unit_fee_yen_kwh,
		TOHOKU: tohoku_unit_fee_yen_kwh,
		TEPCO: tepco_unit_fee_yen_kwh,
		CHUBU: chubu_unit_fee_yen_kwh,
		HOKUDEN: hokuden_unit_fee_yen_kwh,
		KEPCO: kepco_unit_fee_yen_kwh,
		CHUGOKU: chugoku_unit_fee_yen_kwh,
		YONDEN: yonden_unit_fee_yen_kwh,
		KYUDEN: kyuden_unit_fee_yen_kwh,
	};

	const [status, setStatus] = useState<FormStatus>('idle');
	const [formState, setFormState] = useState<FormState>({});
	const [estimate, setEstimate] = useState(0);

	const errorMessage = '要求の処理でエラーがありました。';

	const usageOptions = [
		{
			value: 'LOW',
			label: '1人暮らし',
		},
		{
			value: 'MEDIUM',
			label: '2~3人暮らし',
		},
		{
			value: 'HIGH',
			label: '4人以上',
		},
	];

	const areaOptions = SUPPORTED_SIMPLE_GRID_OPERATOR_NAMES.map(
		(gridOperator) => {
			return {
				label: gridAreaLabelByGridOperatorName[gridOperator],
				value: gridOperator,
			};
		}
	);

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const { gridOperator, usageTier } = formState;

		try {
			if (!gridOperator || !usageTier) {
				throw new Error('Missing grid operator or usage amount');
			}

			const result = calculateMonthlyRate(
				{ gridOperator, amount: gridOperatorUnitFees[gridOperator] },
				usageTier,
				renewable_energy_charge
			);

			if (isNaN(result) || result <= 0) {
				throw new Error('Calculation result is invalid');
			}

			setEstimate(result);
			setStatus('complete');
			sendSimpleOctopusCalculatorAnalytics(gridOperator, usageTier);
		} catch (e) {
			setStatus('error');
			Sentry.captureException('Unable to calculate Simple Octopus rate', {
				extra: { error: e, ...formState },
			});
		}
	};

	const handleResetClick = () => {
		setFormState({} as FormState);
		setStatus('idle');
	};

	const handleUsageSelectChange = (option?: SelectOption | null) => {
		setFormState((prevState) => {
			return {
				...prevState,
				usageTier: toUsageTier(String(option?.value)),
			};
		});
	};

	const handleAreaSelectChange = (gridOperator?: GridOperatorName) => {
		setFormState((prevState) => {
			return {
				...prevState,
				gridOperator,
			};
		});
	};

	return (
		<Card padding="none">
			<Card.Header title={heading} />
			<Card.Body padding="xsmall">
				{status === 'idle' ? (
					<form onSubmit={handleSubmit}>
						<Stack direction="vertical" gap="md">
							<Stack.Item>
								<Select
									label={area_select_label}
									values={areaOptions}
									onChange={(option) =>
										handleAreaSelectChange(option?.value as GridOperatorName)
									}
								/>
							</Stack.Item>
							<Stack.Item>
								<Select
									label={usage_select_label}
									values={usageOptions}
									onChange={handleUsageSelectChange}
								/>
							</Stack.Item>
							<Stack.Item>
								<Button
									type="submit"
									fullWidth
									disabled={!formState.usageTier || !formState.gridOperator}
								>
									{submit_button_text}
								</Button>
							</Stack.Item>
						</Stack>
					</form>
				) : status === 'error' ? (
					<Stack direction="vertical">
						<Stack.Item>
							<Alert severity="error">{errorMessage}</Alert>
						</Stack.Item>
						<Stack.Item>
							<ResetButton
								label={reset_button_text}
								onClick={handleResetClick}
							/>
						</Stack.Item>
					</Stack>
				) : (
					<Stack direction="vertical">
						<Stack.Item>
							<p className="mb-4 text-center text-3xl font-bold">
								{currencyFormatter.format(estimate)}
								<span className="text-sm">円/月</span>
							</p>
						</Stack.Item>
						<Stack.Item>
							<Button href="/join?step=estimation" fullWidth>
								今すぐ申し込む
							</Button>
						</Stack.Item>
						<Stack.Item>
							<ResetButton
								label={reset_button_text}
								onClick={handleResetClick}
							/>
						</Stack.Item>
					</Stack>
				)}
			</Card.Body>
		</Card>
	);
};
