import { datadogLogs } from '@datadog/browser-logs';

// eslint-disable-next-line no-restricted-imports
import { PROJECT_ID } from '@/../sentry.common.config';
import { getBrowserId } from '@/utils/browserId';
import { scrubPII } from '@/utils/constants/logging';

export const env = `oejp-${
	process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
		? 'prod'
		: process.env.NEXT_PUBLIC_ENVIRONMENT === 'test'
			? 'test'
			: 'dev'
}`;
export const service = 'oejp-consumer-site';

let isDatadogLoggerAlreadyInitialized = false;

export const initializeDatadogLogger = (): void => {
	if (!isDatadogLoggerAlreadyInitialized) {
		const browserId = getBrowserId();

		datadogLogs.init({
			clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? '',
			env,
			forwardErrorsToLogs: false,
			sessionSampleRate: 100,
			site: 'datadoghq.eu',
			beforeSend: (event) => {
				for (const key in event) {
					event[key] = scrubPII(event[key]);
				}
				return true;
			},
		});
		isDatadogLoggerAlreadyInitialized = true;

		datadogLogs.setGlobalContext({
			browserId,
			correlatedEvents: {
				datadog: `https://app.datadoghq.eu/logs?${new URLSearchParams({
					query: `service:${service} @browserId:"${browserId}" env:${env}`,
				}).toString()}`,
				sentry: `https://kraken-tech.sentry.io/issues/?${new URLSearchParams({
					project: PROJECT_ID,
					query: `browser_id:"${browserId}"`,
				})}`,
			},
			service,
		});
	}
};
