import { SbBlokData } from '@storyblok/react';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { createElement, FC, ReactNode, useState } from 'react';
import {
	MARK_LINK,
	MARK_UNDERLINE,
	NODE_HEADING,
	NODE_PARAGRAPH,
} from 'storyblok-rich-text-react-renderer';

import { CMSSparkles } from '@/components/storyblok/bloks/CMSSparkles';
import { DynamicComponent } from '@/components/storyblok/index';
import { copy } from '@/copy';
import {
	ReferralSchemeTypeChoices,
	ReferralType,
} from '@/services/typed-graphql-sdk';
import { getFirstName } from '@/utils/constants/marketing';
import { currencyFormatter } from '@/utils/formatters/currencyFormatter';

const headingMap: { [key: number]: string } = {
	1: 'font-bold text-4xl sm:text-4.5xl my-8 leading-[4.5rem]',
	2: 'text-3xl sm:text-3.5xl mb-8 mt-6 leading-[3.5rem]',
	3: 'font-bold text-2xl sm:text-2.5xl mb-6 mt-6 leading-[3rem]',
	4: 'text-xl mb-2 mt-4 font-bold leading-10',
	5: 'text-lg font-bold leading-9',
	6: 'text-lg font-bold leading-9',
};

export const RICH_TEXT_CONSTANTS = (
	extraProps?: object
): {
	[key: string]:
		| ((name: string, props: SbBlokData) => JSX.Element)
		| {
				link: (
					children: ReactNode,
					props: { href: string; linktype?: string; target?: string }
				) => JSX.Element;
		  }
		| JSX.Element;
} => {
	return {
		defaultBlokResolver: (name: string, props: SbBlokData) => {
			return (
				<DynamicComponent
					blok={{ ...props, component: name }}
					props={extraProps}
				/>
			);
		},
		markResolvers: {
			[MARK_LINK]: (
				children: ReactNode,
				props: {
					href: string;
					linktype?: string;
					target?: string;
				}
			) => {
				const { href, target, linktype } = props;
				if (!href) {
					// Links without href: display only children
					return <a className="text-voltage underline">{children}</a>;
				}
				if (linktype === 'email') {
					// Email links: add `mailto:` scheme and map to <a>
					return (
						<a href={`mailto:${href}`} className="text-voltage underline">
							{children}
						</a>
					);
				}
				if (href.match(/^(https?:)?\/\//)) {
					// External links: map to <a>
					return (
						<a href={href} target={target} className="text-voltage underline">
							{children}
						</a>
					);
				}
				// Internal links: map to <Link>
				return (
					<Link href={href} legacyBehavior>
						<a className="text-voltage underline">{children}</a>
					</Link>
				);
			},
		},
	};
};

export const RICH_TEXT_NODE_RESOLVERS_HEADING: { [key: string]: ReactNode } = {
	// @ts-expect-error
	[NODE_HEADING]: (children: string[], { level }: { level: number }) =>
		createElement(
			`h${level}`,
			{ className: headingMap[level] ?? '' },
			children
		),
};

export const textAlignMap = {
	left: 'text-left',
	right: 'text-right',
	center: 'text-center',
};

export const justifyMap = {
	left: 'justify-start text-left items-start',
	right: 'justify-end text-right items-end',
	center: 'justify-center items-center md:mx-auto text-center',
};

export const RICH_TEXT_NODE_RESOLVERS_PARAGRAPHS = (
	text_align?: 'left' | 'right' | 'center'
): { [key: string]: ReactNode } => {
	return {
		// @ts-expect-error
		[NODE_PARAGRAPH]: (children: ReactNode) => {
			const isChildNonSpanElement =
				Array.isArray(children) &&
				children.findIndex(
					(child: { type: string }) => child?.type !== 'span'
				) !== -1;

			return isChildNonSpanElement ? (
				<p
					className={clsx(textAlignMap[text_align ?? 'left'], {
						'mx-auto': isChildNonSpanElement && text_align === 'center',
						'mr-0 ml-auto': isChildNonSpanElement && text_align === 'right',
						'mr-auto': isChildNonSpanElement && text_align === 'left',
					})}
				>
					{children}
				</p>
			) : (
				children
			);
		},
	};
};

export const CMS_GOOGLE_REVIEW_RESOLVERS = {
	[NODE_PARAGRAPH]: (children: ReactNode): JSX.Element => (
		<span className="inline-block">{children}</span>
	),
	...RICH_TEXT_NODE_RESOLVERS_HEADING,
};

/**
 * @note Do not use MARK_UNDERLINE logic in other Rich Text.
 * This is specifically fixing an aria warnings caused by Reward Banners on our homepage.
 * If used outside of reward banners, the underline button in the Storyblok UI will not work.
 * In all of our reward banners, all underlined text is links so there is no impact from this
 * I've added a custom css class to allow users to underline non-link text in banners if they want to.
 */

export const REWARD_BANNER_RICH_TEXT_CONSTANTS: {
	[key: string]: {
		[key: string]: (
			children: ReactNode,
			props: { href: string; linktype?: string; target?: string }
		) => ReactNode;
	};
} = {
	markResolvers: {
		[MARK_UNDERLINE]: (children: ReactNode) => {
			return children;
		},
		[MARK_LINK]: (
			children: ReactNode,
			props: {
				href: string;
				linktype?: string;
				target?: string;
			}
		) => {
			const { href, target, linktype } = props;
			if (linktype === 'email') {
				// Email links: add `mailto:` scheme and map to <a>
				return (
					<a href={`mailto:${href}`} className="underline">
						{children}
					</a>
				);
			}
			if (href.match(/^(https?:)?\/\//)) {
				// External links: map to <a>
				return (
					<a href={href} target={target} className="underline">
						{children}
					</a>
				);
			}
			// Internal links: map to <Link>
			return (
				<Link href={href} legacyBehavior>
					<a className="underline">{children}</a>
				</Link>
			);
		},
	},
	blokResolvers: {
		// @ts-expect-error because props are now typed as Record<string, unknown> in the library
		['sparkles']: (blok: CMSSparklesProps['blok']) => (
			<CMSSparkles blok={blok} />
		),
	},
	nodeResolvers: {
		[NODE_PARAGRAPH]: (children: ReactNode) => (
			<span className="inline-block">{children}</span>
		),
	},
};

export enum StoryblokBannerType {
	campaignCustom = 'campaign-custom-banner',
	primary = 'primary-banner',
	secondary = 'secondary-banner',
}

export type AccordionType = 'faq';

export const ReferralHistory: FC<{
	referrals: ReferralType[];
	title?: string;
}> = ({ title = '', referrals }) => {
	const [shouldShowAllReferrals, setShouldShowAllReferrals] = useState(false);
	const { t } = useTranslation();
	return (
		<div className="flex flex-col space-y-5">
			<h2 className="mx-10 text-center text-xl font-bold leading-8">{title}</h2>
			{referrals?.length > 0 && (
				<div className="flex flex-col gap-4 px-6">
					<table>
						<thead>
							<tr className="grid grid-cols-3">
								<th className="text-left">{copy.fullName}</th>
								<th>
									<span>{t('account:friend-referral-card.bonus')}</span>
									<span>{t('account:friend-referral-card.price')}</span>
								</th>
								<th className="text-right">
									<span>{t('account:friend-referral-card.bonus')}</span>
									<span>
										{t('account:friend-referral-card.date-to-get-bonus')}
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{(shouldShowAllReferrals ? referrals : referrals.slice(0, 3))
								.filter(
									(a) =>
										a.schemeType === ReferralSchemeTypeChoices.ReferralReward &&
										a.referringUserPaymentAmount &&
										a.referringUserPaymentAmount > 0
								)
								.map(
									({
										referredUserJoinDate,
										referredUserName,
										referringUserPaymentAmount,
										paymentDate,
									}) => (
										<tr
											data-testid="referred-friend"
											className="mt-4 grid grid-cols-3"
											key={`${referredUserName}-${referredUserJoinDate}`}
										>
											<td>
												{referredUserName && getFirstName(referredUserName)}
											</td>
											<td className="text-center">
												{currencyFormatter.format(
													referringUserPaymentAmount as number
												)}
												円
											</td>
											<td className="text-right">
												{paymentDate
													? format(new Date(paymentDate), 'yyyy/MM/dd')
													: t('account:friend-referral-card.holding')}
											</td>
										</tr>
									)
								)}
						</tbody>
					</table>

					{referrals?.length > 3 && !shouldShowAllReferrals ? (
						<button
							className="text-voltage"
							onClick={() => setShouldShowAllReferrals(true)}
						>
							<span className="underline">{t('common:view-more')}</span>
						</button>
					) : null}
				</div>
			)}
		</div>
	);
};
