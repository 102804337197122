import { Button } from '@krakentech/coral';
import { IconDismiss } from '@krakentech/icons';
import { FC, useEffect } from 'react';

import { Link } from '@/components/shared/Link';
import { copy } from '@/copy';
import { machine, StateSchema, Event } from '@/machines/cookies/machine';
import { useMachine } from '@/machines/hooks';
import {
	sendCookieAcceptAnalytics,
	sendCookieDeclineAnalytics,
} from '@/utils/googleAnalytics';

type StyledLinkProps = {
	href: string;
	text: string;
};

const StyledLink: FC<StyledLinkProps> = ({ href, text }) => (
	<Link href={href} color="inherit">
		<span className="underline">{text}</span>
	</Link>
);

/**
 *
 * @note This cookies banner is not actually functional because we control scripts through AnalyticsScript.tsx.
 * See https://octoenergy.slack.com/archives/C01R4M8QUGZ/p1701741669772239 for further non-technical explanation.
 * In future we will have to decide between opt-in consent and opt-out consent.
 *
 */

const CookiesBanner: FC = () => {
	const [state, send] = useMachine<unknown, StateSchema, Event>(machine);

	useEffect(() => {
		send({ type: 'LOAD' });
	}, []);

	if (
		state.matches('initial') ||
		state.matches('checkingPrivacyCookie') ||
		state.matches('dismissedPrivacyCookieMessage') ||
		state.matches('closedMessage')
	) {
		return null;
	}
	return (
		<section className="fixed bottom-3 z-50 w-full text-ice">
			<div className="mx-2 max-w-6xl items-center rounded-t-lg border-2 border-ink bg-hemocyanin px-4 pt-2 sm:flex sm:py-2 lg:mx-auto">
				<div className="sm:flex sm:items-center">
					<div className="mr-8 flex-1 text-base sm:mr-2">
						<span>
							<span>
								{/* This website uses cookies for improving your
                            experience and delivering advertisements. See [link]
                            for more information. */}
								このサイトでは、お客さまの利便性向上や広告配信等を目的として、Cookieを利用してお客様のアクセス情報を取得します。詳細については
								<StyledLink
									text={copy.aboutUsingThisSite}
									href="/terms#website-terms-and-conditions"
								/>
								をご覧下さい。
							</span>
						</span>
					</div>
					<div className="my-2 flex justify-center sm:my-0 sm:items-center">
						<Button
							onClick={() => {
								sendCookieAcceptAnalytics();
								send('DISMISS_COOKIE_MESSAGE');
							}}
							aria-label={copy.close}
							size="small"
						>
							{/* Accept cookies */}
							Cookieの利用に同意
						</Button>
					</div>
				</div>
				<button
					onClick={() => {
						sendCookieDeclineAnalytics();
						send('CLOSE_MESSAGE');
					}}
					className="absolute top-2 right-4 p-1 transition-all hover:scale-110 sm:relative sm:top-0 sm:right-0 sm:ml-2"
					aria-label={copy.close}
				>
					<IconDismiss size={16} />
				</button>
			</div>
		</section>
	);
};

export { CookiesBanner };
